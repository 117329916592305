$(document).ready(function(){

  $('.owl-carousel').owlCarousel({
    'items': 1,
    'nav': true,
    'dots': true,
    'dotsEach': 1,
    'navText' : [
      "<i class='fa-solid fa-chevron-left'></i>",
      "<i class='fa-solid fa-chevron-right'></i>",
    ]
  });

});