$(document).ready(function(){

  $('.s-header__burger').click(function(){
    $('body').addClass('s-body--modal-open');
    $('.s-header').addClass('s-header--open');
  });

  $('.s-header__close').click(function(){
    $('body').removeClass('s-body--modal-open');
    $('.s-header').removeClass('s-header--open');
  });

});