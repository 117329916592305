$(document).ready(function(){

  $('.c-form__field--select').each(function(){
    var select = $(this).find('select');

    function update_color(){
      if (select.val() == ''){
        select.css('color', '#B1BAC5');
      } else {
        select.css('color', '#000000');
      }
    }

    update_color();
    select.change(function(){
      update_color();
    });

  });

});