$(document).ready(function() {
    var carousel = $('.s-cslist__grid--mob-carousel');
    if (carousel.length) {
        // We have a carousel

        function set_to_mobile(){
            carousel.addClass('owl-carousel owl-theme');
            carousel.owlCarousel({
                items: 1,
                loop:true,
                nav:false,
            });
        }

        function set_to_desktop(){
            carousel.owlCarousel('destroy');
            carousel.removeClass('owl-carousel owl-theme');
        }

        var size = '';

        if ($(window).width() < 768) {
            size = 'mobile';
            set_to_mobile()
        } else {
            size = 'desktop';
            set_to_desktop()
        }

        $(window).resize(function() {
            if ($(window).width() < 768 && size !== 'mobile') {
                size = 'mobile';
                set_to_mobile();
            } else if ($(window).width() >= 768 && size !== 'desktop') {
                size = 'desktop';
                set_to_desktop()
            }
        });
    }
});