import $ from 'jquery';
window.$ = window.jQuery = $;

require('bootstrap');
require('owl.carousel');

require('./sections/s-header.js');
require('./sections/s-casec.js');
require('./sections/s-cslist.js');
require('./sections/s-cookies.js');

require('./comps/c-form.js');
require('./comps/c-block.js');
